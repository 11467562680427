<template>
  <!-- category on page -->
  <section id="pricing" class="bg-light mt-5 py-4">
    <div class="container-lg">
      <div class="text-center">
        
        <p class="h2 ">Freelance Services</p>
        <p class="lead text-muted">Contact us we give you technological solution of your problem like full automations in your workshop, information analysis, security</p>
      </div>
    </div>
    <div class="row my-5 align-items-center justify-content-center g-5">
      <!-- col width in different size -->
      <div class="col-9 col-lg-4 col-xl-3">
        <div class="card border-0">
          <div class="card-body text-center py-4">
             <h4 class="card-title">Android & ios app</h4>
             <p class="lead card-subtitle"></p>
             <p class="display-4 my-4 text-primary fw-bold">Mobile App Development</p>
             <p class="card-text mx-5 text-muted d-lg-block">Responsive and Userfriendly UI App, AI integrated App, Cloud services Intergrated</p>
             <div class="btn btn-outline-primary btn-lg mt-3" href="https://wa.me/+255621561022?text=Mobile App development"> Build Now</div>
          </div>
        </div>
      </div>

      <div class="col-10 col-lg-4">
        <div class="card border-primary border-2 ">
          <div class="card-header text-center text-primary">Mostly popular</div>
          <div class="card-body text-center py-4">
             <p class="lead card-subtitle">Information system, administration system, Push Bulky sms system, Bussiness System</p>
             <p class="display-4  my-4 text-primary fw-bold">Web System Development</p>
             <p class="card-text mx-5 text-muted d-none d-lg-block">Beutiful userfriendly UI, Responsive,Cheap and  Robustness System, Accesible and effecient</p>
             <a class="btn btn-outline-primary btn-lg mt-3" href="https://wa.me/+255621561022?text=Web System Development">Build Now</a>
          </div>
        </div>
      </div>

      <div class="col-9 col-lg-4 col-xl-3">
        <div class="card border-0">
          <div class="card-body text-center py-4">
             <h4 class="card-title">software project done</h4>
             <p class="lead card-subtitle">Information site, e commerce website, Push bulky Sms,  Mobile application</p><br>
             <p class="lead card-subtitle">Total Projects done ready</p>
             <p class="display-4 my-4 text-primary fw-bold">5</p>
             <a class="btn btn-outline-primary btn-lg mt-3">Employ Us</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</template>

<script>
export default {

}
</script>

<style scoped>
a{
    text-decoration: none;
}
</style>
<template>
  <footer>
    <div class="col-sm-3 mx-auto py-4 text-white align-items-center justify-content-center text-center">
      <div class="row justify-content-center align-items-center">
        <div class="fw-bold h6 my-2 ">Contact Us:</div>
      </div>
        <a class="p text-white" href="tel:+255621561022" >+255621561022</a>,
        <a href="email:info@silconi.com" class="p text-white" style="font-size: 18px;">
         info@silconi.com
       </a>
       <div>P.O.Box 251 Mbozi</div>
       <div>Songwe, Dar es Salaam Tanzania</div>
       
       <div class="small pt-2">Product of <span class="fw-bold">Silconi</span>@2023</div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.line{
  height: 5px;
  width: 20px;
  background-color: #E8491D;
}
footer{
  background-color:black;
}
a{
  text-decoration: none;
}
</style>
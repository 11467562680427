<template>
  <h2>404</h2>
  <h3>Page not found</h3>
    <div class="my-5 pd-5">
        <div @click="redirect" class="fa fa-home text-black col-4"></div>
        <div @click="back" class="fa fa-arrow-left text-black col-4"></div>
        <div @click="foward" class="fa fa-arrow-right text-black col-4"></div>   
    </div>
   
  
</template>

<script>
export default {

}
</script>

<style>

</style>
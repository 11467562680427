<template>
  <div class="text-start container py-4">
  <h2 class="text-center">Terms and conditions</h2>
  <div class="h4">Introduction</div>
  By using this website, you agree to the terms and conditions set forth in this document. These terms and conditions apply to all users of the website and all transactions made through the website.

<div class="h4">Product Information</div>
We make every effort to ensure that the information provided on the website is accurate and up-to-date. However, we cannot guarantee the accuracy of product descriptions, 
specifications, or pricing. We reserve the right to correct any errors or omissions, 
and to update product information at any time without prior notice.

<div class="h4">Orders and Payment</div>
When you place an order on our website, you agree to pay the full amount due at the time of purchase. 
We accept payment via credit card, PayPal, or other payment methods that we may offer from time to time. 
We reserve the right to cancel any order if we suspect fraudulent activity or if the product is no longer available.

<div class="h4">Shipping and Delivery</div>
We will ship your order to the address you provide during the checkout process. 
We cannot guarantee delivery dates, and we are not responsible for any delays or 
damages that may occur during shipping. We may use a third-party shipping provider, 
and their terms and conditions will apply to the shipment of your order.

<div class="h4">Returns and Refunds</div>
If you are not satisfied with your purchase, you may return it within 30 days for a full refund or exchange. 
The product must be returned in its original condition, with all packaging and accessories included. 
We are not responsible for any shipping costs associated with returns or exchanges, and we reserve the right to charge a restocking fee.

<div class="h4">Warranty</div>
All products sold on our website come with a limited warranty. 
The duration and terms of the warranty may vary depending on the product. 
Please refer to the product description for details. 
This warranty applies only to defects in materials and workmanship and does not cover damage caused by misuse, neglect, or accidents.

<div class="h4">Limitation of Liability</div>
To the maximum extent permitted by law, we are not liable for any damages, 
including but not limited to, lost profits, lost savings, or any incidental or 
consequential damages arising out of the use or inability to use our products or website.

<div class="h4">Governing Law</div>
These terms and conditions are governed by the laws of Tanzania. 
Any dispute arising out of or related to these terms and conditions or the use of our website will be resolved exclusively in the courts of Tanzania.

<div class="h4">Changes to Terms and Conditions</div>
We reserve the right to modify these terms and conditions at any time, 
without prior notice. Your continued use of the website after any changes to these terms and conditions will constitute your acceptance of the modified terms.

<div class="h4">Contact Us</div>
If you have any questions or concerns about these terms and conditions, 
please contact us at +255621561022.

  </div>
</template>

<script>
export default {
  
}
</script>

<style>

</style>